import * as React from 'react';
import { Helmet } from 'react-helmet';

import Layout from '../components/Layout';
import ContactForm from '../components/ContactForm';

const ContactUsPage = (): JSX.Element => {
    return (
        <>
            <Helmet title="Contact Us" />
            <Layout>
                <div className="page">
                    <h1 className="page__title">Contact Us</h1>
                    <p>Simply fill out a few details and click submit. We will contact you shortly.</p>
                    <ContactForm />
                </div>
            </Layout>
        </>
    );
};

export default ContactUsPage;
