import * as React from 'react';
import axios, { AxiosResponse } from 'axios';

import { validateForm } from '../utils/validation';
import { MIN_LENGTH, EMAIL_REGEX, IS_INVALID, IS_REQUIRED } from '../constants';
import CTA from './CTA';

interface ContactFormParams {
    name: string;
    email: string;
    message: string;
    errors: {
        name: boolean | null;
        email: boolean | null;
        message: boolean | null;
    };
}
type FieldNames = 'name' | 'email' | 'message';

const ContactForm = (): JSX.Element => {
    const [disabled, setDisabled]: [boolean, Function] = React.useState<boolean>(true);
    const [formSent, setFormSent]: [boolean, Function] = React.useState<boolean>(false);
    const [formError, setFormError]: [boolean, Function] = React.useState<boolean>(false);
    const [state, setState]: [ContactFormParams, Function] = React.useState<ContactFormParams>({
        name: '',
        email: '',
        message: '',
        errors: {
            name: null,
            email: null,
            message: null,
        },
    });

    const handleChange = (e: { target: HTMLInputElement | HTMLTextAreaElement }) => {
        const value = e.target.value;
        const name = e.target.name as FieldNames;
        const errors = state.errors;

        switch (name) {
            case 'email':
                errors[name] = !EMAIL_REGEX.test(value);
                break;
            default:
                errors[name] = value.length < MIN_LENGTH ? true : false;
                break;
        }

        setDisabled(!validateForm(errors));
        setFormError(!validateForm(errors));
        setState((prevState: ContactFormParams) => {
            return {
                ...prevState,
                [name]: value,
            };
        });
    };

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const formData = new FormData();
        formData.append('name', state.name.trim());
        formData.append('email', state.email.trim());
        formData.append('message', state.message.trim());

        const apiBaseUrl = process.env.GATSBY_API_URL as string;
        axios({
            method: 'post',
            url: 'contact.php',
            baseURL: apiBaseUrl,
            withCredentials: true,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            data: formData,
        })
            .then(function (response: AxiosResponse) {
                setFormSent(true);
            })
            .catch(function (error: any) {
                setFormError(true);
            });
    };

    const message: JSX.Element = (
        <div className="form-wrapper">
            <h3 className="form__title">Thanks!</h3>
            <p className="form__description">Your message has been sent!</p>
        </div>
    );

    const form: JSX.Element = (
        <div className="form-wrapper">
            <form className="form" onSubmit={handleSubmit}>
                <fieldset>
                    <div className="row">
                        <label htmlFor="name" className={`label ${IS_REQUIRED} ${state.errors.name ? IS_INVALID : ''}`} aria-label="Name">
                            <input className="input" type="text" placeholder="Name" name="name" id="name" value={state.name} onChange={handleChange} />
                        </label>
                        <label htmlFor="email" className={`label ${IS_REQUIRED} ${state.errors.email ? IS_INVALID : ''}`} aria-label="Email">
                            <input className="input" type="email" placeholder="E-mail" name="email" id="email" value={state.email} onChange={handleChange} />
                        </label>
                    </div>
                    <div className="row">
                        <label htmlFor="message" className={`label ${IS_REQUIRED} ${state.errors.message ? IS_INVALID : ''}`} aria-label="Message">
                            <textarea className="textarea" placeholder="Message" name="message" id="message" value={state.message} onChange={handleChange} />
                        </label>
                    </div>
                    <div className="row">
                        <CTA classname="submit" text="Send" type="submit" disabled={disabled} />
                        {formError && <div className="error">Please fill in required fields</div>}
                    </div>
                </fieldset>
            </form>
        </div>
    );

    return formSent ? message : form;
};

export default ContactForm;
